:root {
    --common-colors-blue-proper-plus1-dark: #0f3699;
    --common-colors-blue-proper-0-regular: #083fcf;
    --common-colors-blue-proper-minus1-light: #2b62f3;

    --common-colors-green-highlight-plus1-dark: #dcf2ea;
    --common-colors-green-highlight-0-regular: #eef8f4;
    --common-colors-green-highlight-minus1-light: #f5fbf8;

    --common-colors-green-proper-plus1-dark: #088a59;
    --common-colors-green-proper-0-regular: #17ae75;
    --common-colors-green-proper-minus1-light: #41d19b;

    --common-colors-red-highlight-plus1-dark: #f4b6b6;
    --common-colors-red-highlight-0-regular: #f9dada;
    --common-colors-red-highlight-minus1-light: #fdf4f4;

    --common-colors-red-proper-plus1-dark: #a70f0f;
    --common-colors-red-proper-0-regular: #da2424;
    --common-colors-red-proper-minus1-light: #f05252;

    --common-colors-yellow-highlight-plus1-dark: #fff3e3;
    --common-colors-yellow-highlight-0-regular: #fff8eb;
    --common-colors-yellow-highlight-minus1-light: #fffdf2;

    --common-colors-yellow-proper-plus1-dark: #ED9419;
    --common-colors-yellow-proper-0-regular: #ffb732;
    --common-colors-yellow-proper-minus1-light: #ffd464;

    --neutral-0: #ffffff;
    --neutral-50: #fafbff;
    --neutral-75: #f9fafc;
    --neutral-100: #f4f6fa;
    --neutral-200: #edeff5;
    --neutral-300: #e6e8f0;
    --neutral-400: #d8dae5;
    --neutral-500: #c1c4d6;
    --neutral-600: #8f95b2;
    --neutral-700: #696f8c;
    --neutral-800: #474d66;
    --neutral-900: #252836;
    --neutral-950: #1a1d26;
    --neutral-1000: #000000;
}

:root {
    --color-tokens-body-body-background: var(--neutral-100);
    --color-tokens-body-body-placeholder: var(--neutral-400);
    --color-tokens-border-default: var(--neutral-400);
    --color-tokens-button-button-primary-btn-primary-background: var(--neutral-700);
    --color-tokens-button-button-primary-btn-primary-text: var(--neutral-0);
    --color-tokens-button-button-secondary-btn-secondary-background: #ffffff;
    --color-tokens-button-button-secondary-btn-secondary-border: var(--color-tokens-border-default);
    --color-tokens-button-button-secondary-btn-secondary-text: var(--color-tokens-button-button-primary-btn-primary-background);
    --color-tokens-icon: var(--neutral-800);
    --color-tokens-sidebar-menu-menu-item-active: var(--neutral-900);
    --color-tokens-sidebar-menu-menu: var(--neutral-800);
    --color-tokens-text-input-and-dropdowns-text-input-background: var(--neutral-0);
    --color-tokens-text-input-and-dropdowns-text-input-border: var(--color-tokens-border-default);
    --color-tokens-text-input-and-dropdowns-text-input-text-filled: var(--neutral-1000);
    --color-tokens-text-input-and-dropdowns-text-input-text-placeholder: var(--neutral-600);
    --color-tokens-white: var(--neutral-0);
}