/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_dadc75';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_dadc75';src: local("Arial");ascent-override: 99.71%;descent-override: 34.82%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_dadc75 {font-family: '__Nunito_dadc75', '__Nunito_Fallback_dadc75';font-style: normal
}.__variable_dadc75 {--default-font: '__Nunito_dadc75', '__Nunito_Fallback_dadc75'
}

/* latin-ext */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_27b509';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Outfit_Fallback_27b509';src: local("Arial");ascent-override: 100.18%;descent-override: 26.05%;line-gap-override: 0.00%;size-adjust: 99.82%
}.__className_27b509 {font-family: '__Outfit_27b509', '__Outfit_Fallback_27b509';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_6db340';src: local("Arial");ascent-override: 99.71%;descent-override: 34.82%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_6db340 {font-family: '__Nunito_6db340', '__Nunito_Fallback_6db340';font-style: normal
}.__variable_6db340 {--default-font: '__Nunito_6db340', '__Nunito_Fallback_6db340'
}

:root {
    --common-colors-blue-proper-plus1-dark: #0f3699;
    --common-colors-blue-proper-0-regular: #083fcf;
    --common-colors-blue-proper-minus1-light: #2b62f3;

    --common-colors-green-highlight-plus1-dark: #dcf2ea;
    --common-colors-green-highlight-0-regular: #eef8f4;
    --common-colors-green-highlight-minus1-light: #f5fbf8;

    --common-colors-green-proper-plus1-dark: #088a59;
    --common-colors-green-proper-0-regular: #17ae75;
    --common-colors-green-proper-minus1-light: #41d19b;

    --common-colors-red-highlight-plus1-dark: #f4b6b6;
    --common-colors-red-highlight-0-regular: #f9dada;
    --common-colors-red-highlight-minus1-light: #fdf4f4;

    --common-colors-red-proper-plus1-dark: #a70f0f;
    --common-colors-red-proper-0-regular: #da2424;
    --common-colors-red-proper-minus1-light: #f05252;

    --common-colors-yellow-highlight-plus1-dark: #fff3e3;
    --common-colors-yellow-highlight-0-regular: #fff8eb;
    --common-colors-yellow-highlight-minus1-light: #fffdf2;

    --common-colors-yellow-proper-plus1-dark: #ED9419;
    --common-colors-yellow-proper-0-regular: #ffb732;
    --common-colors-yellow-proper-minus1-light: #ffd464;

    --neutral-0: #ffffff;
    --neutral-50: #fafbff;
    --neutral-75: #f9fafc;
    --neutral-100: #f4f6fa;
    --neutral-200: #edeff5;
    --neutral-300: #e6e8f0;
    --neutral-400: #d8dae5;
    --neutral-500: #c1c4d6;
    --neutral-600: #8f95b2;
    --neutral-700: #696f8c;
    --neutral-800: #474d66;
    --neutral-900: #252836;
    --neutral-950: #1a1d26;
    --neutral-1000: #000000;
    --color-tokens-body-body-background: var(--neutral-100);
    --color-tokens-body-body-placeholder: var(--neutral-400);
    --color-tokens-border-default: var(--neutral-400);
    --color-tokens-button-button-primary-btn-primary-background: var(--neutral-700);
    --color-tokens-button-button-primary-btn-primary-text: var(--neutral-0);
    --color-tokens-button-button-secondary-btn-secondary-background: #ffffff;
    --color-tokens-button-button-secondary-btn-secondary-border: var(--color-tokens-border-default);
    --color-tokens-button-button-secondary-btn-secondary-text: var(--color-tokens-button-button-primary-btn-primary-background);
    --color-tokens-icon: var(--neutral-800);
    --color-tokens-sidebar-menu-menu-item-active: var(--neutral-900);
    --color-tokens-sidebar-menu-menu: var(--neutral-800);
    --color-tokens-text-input-and-dropdowns-text-input-background: var(--neutral-0);
    --color-tokens-text-input-and-dropdowns-text-input-border: var(--color-tokens-border-default);
    --color-tokens-text-input-and-dropdowns-text-input-text-filled: var(--neutral-1000);
    --color-tokens-text-input-and-dropdowns-text-input-text-placeholder: var(--neutral-600);
    --color-tokens-white: var(--neutral-0);
}
